import isEqual from 'react-fast-compare';
import {createApi} from '@reduxjs/toolkit/query/react';
import omit from 'lodash/omit';
import {
  transformApplicationFormStructureResponse,
  transformJobResponse,
  transformJobsResponse,
  transformPaginatedCompanyResponse,
  transformRecentSearchesResponse,
  transformSponsorsResponse,
  transformUserData,
  transformUserPastApplications
} from 'job-board/api/transformers';
import type {ApplyParams, ApplyResponse} from 'job-board/api/types/apply';
import type {FindAvatarUsingEmailParams, FindAvatarUsingEmailResponse} from 'job-board/api/types/findAvatarUsingEmail';
import type {GetApplicationFormStructureResponse} from 'job-board/api/types/getApplicationFormStructure';
import type {GetFileUploadConfigParams, GetFileUploadConfigResponse} from 'job-board/api/types/getFileUploadConfig';
import type {GetJobResponse, TransformedJob} from 'job-board/api/types/getJob';
import type {
  GetJobsParams,
  GetJobsResponse,
  GetPaginatedJobsParams,
  TransformedJobs
} from 'job-board/api/types/getJobs';
import type {
  GetPaginatedCompanyParams,
  GetPaginatedCompanyResponse,
  TransformedPaginatedCompany
} from 'job-board/api/types/getPaginatedCompany';
import type {TransformedRecentSearches} from 'job-board/api/types/getRecentSearches';
import type {TransformedGetSponsorsResponse} from 'job-board/api/types/getSponsors';
import type {GetUploadedCVDetailsParams, GetUploadedCVDetailsResponse} from 'job-board/api/types/getUploadedCvDetails';
import type {GetUserDataResponse} from 'job-board/api/types/getUserData';
import type {
  GetUserPastApplicationsParams,
  TransformedUserPastApplications
} from 'job-board/api/types/getUserPastApplications';
import type {GetUserProfilePreferencesResponse} from 'job-board/api/types/getUserProfilePreferences';
import type {GetUserProfileResponse} from 'job-board/api/types/getUserProfileResponse';
import type {JobAttributionParams} from 'job-board/api/types/jobAttribution';
import type {PatchUserProfileParams} from 'job-board/api/types/patchUserProfile';
import {clearUserDataDueToError, setUserData} from 'job-board/shared/redux/userData/slice';
import {AuthChannel, ErrorCode} from 'job-board/shared/services/auth';
import buildUrl from 'job-board/shared/utils/buildUrl';
import customBaseQuery from './customBaseQuery';
import {getPaginatedJobsQuery, jobAttributionQuery} from './queryCreators';

export const jobBoardApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    apply: builder.mutation<ApplyResponse, ApplyParams>({
      query: ({formValues, headers, jobId, jobPosition, lng}) => ({
        body: {
          candidate: formValues,
          job: {
            position: jobPosition
          }
        },
        credentials: 'same-origin',
        headers,
        method: 'POST',
        url: `/api/v1/jobs/${jobId}/apply?lng=${lng}`
      })
    }),
    companyAttribution: builder.mutation<undefined, string>({
      query: id => ({
        credentials: 'same-origin',
        method: 'POST',
        url: `/api/v1/companies/${id}/attribute`
      })
    }),
    findAvatarUsingEmail: builder.mutation<FindAvatarUsingEmailResponse, FindAvatarUsingEmailParams>({
      query: ({email, headers}) => ({
        body: {email},
        headers,
        method: 'POST',
        url: '/api/v1/avatar'
      })
    }),
    getApplicationFormStructureById: builder.query<GetApplicationFormStructureResponse, {jobId: string; lng?: string}>({
      providesTags: ['ApplicationFormStructure'],
      query: ({jobId, lng}) => {
        const baseUrl = `/api/v1/jobs/${jobId}/form?includeAccountMetadata=true`;

        return lng ? `${baseUrl}&lng=${lng}` : baseUrl;
      },
      transformResponse: transformApplicationFormStructureResponse
    }),
    getFileUploadConfig: builder.query<GetFileUploadConfigResponse, GetFileUploadConfigParams>({
      query: ({id, jobId, params}) => ({
        method: 'GET',
        params,
        url: `/api/v1/jobs/${jobId}/form/upload/${id ?? 'undefined'}`
      })
    }),
    getJobById: builder.query<TransformedJob, string>({
      query: id => `/api/v1/jobs/${id}`,
      transformResponse: (response: GetJobResponse) => transformJobResponse(response)
    }),
    getJobs: builder.query<TransformedJobs, GetJobsParams>({
      query: queryParams => buildUrl('/api/v1/jobs', queryParams),
      transformResponse: transformJobsResponse
    }),
    getPaginatedCompanyById: builder.query<TransformedPaginatedCompany, GetPaginatedCompanyParams>({
      forceRefetch: ({currentArg, previousArg}) => !isEqual(currentArg, previousArg),
      keepUnusedDataFor: 0,
      merge: (currentCache, newResponse, {arg}) => {
        return arg.pageToken
          ? {
              ...newResponse,
              jobs: [...(currentCache.jobs || []), ...newResponse.jobs]
            }
          : newResponse;
      },
      query: ({id, ...queryParams}) => buildUrl(`/api/v1/companies/${id}`, {...queryParams}),
      serializeQueryArgs: ({queryArgs}) => omit(queryArgs, ['pageToken']),
      transformResponse: (response: GetPaginatedCompanyResponse) => transformPaginatedCompanyResponse(response)
    }),
    getPaginatedJobs: builder.query<TransformedJobs, GetPaginatedJobsParams>({
      forceRefetch: ({currentArg, previousArg}) => !isEqual(currentArg, previousArg),
      keepUnusedDataFor: 0,
      merge: (currentCache, newResponse, {arg}) => {
        return arg.pageToken
          ? {
              ...newResponse,
              jobs: [...(currentCache.jobs || []), ...newResponse.jobs]
            }
          : newResponse;
      },
      query: getPaginatedJobsQuery,
      serializeQueryArgs: ({queryArgs}) => omit(queryArgs, ['pageToken']),
      transformResponse: (response: GetJobsResponse, meta: {request: Request; response: Response}) =>
        transformJobsResponse(response, meta)
    }),
    getRecentSearches: builder.query<TransformedRecentSearches, undefined>({
      query: () => `/api/v1/recent-searches`,
      transformResponse: transformRecentSearchesResponse
    }),
    getSimilarJobsById: builder.query<Array<TransformedJob>, string>({
      query: id => `/api/v1/jobs/${id}/similar`,
      transformResponse: (response: Array<GetJobResponse>) => response.map(transformJobResponse)
    }),
    getSponsors: builder.query<TransformedGetSponsorsResponse, undefined>({
      query: () => '/api/v1/sponsored-companies',
      transformResponse: transformSponsorsResponse
    }),
    getTitleSearchAutocomplete: builder.query<string[], {term: string}>({
      query: ({term}) => ({
        method: 'GET',
        params: {term},
        url: '/api/v1/title-search-autocomplete'
      })
    }),
    getUploadedCVDetails: builder.query<GetUploadedCVDetailsResponse, GetUploadedCVDetailsParams>({
      query: ({headers, jobId, lng, params, signal}) => ({
        headers,
        method: 'GET',
        params: {...params, lng},
        signal,
        url: `/api/v1/jobs/${jobId}/autofill`
      })
    }),
    getUserData: builder.query<GetUserDataResponse, undefined>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        const authChannel = AuthChannel.getInstance();
        try {
          const response = await queryFulfilled;
          dispatch(setUserData(response.data));
          authChannel.postMessage({success: response.data});
        } catch {
          authChannel.postMessage({error: {code: ErrorCode.RESPONSE_ERROR}});
          dispatch(clearUserDataDueToError());
        }
      },
      providesTags: ['UserData'],
      query: () => '/api/v2/user',
      transformResponse: transformUserData
    }),
    getUserPastApplications: builder.query<TransformedUserPastApplications, GetUserPastApplicationsParams>({
      forceRefetch: ({currentArg, previousArg}) => !isEqual(currentArg, previousArg),
      keepUnusedDataFor: 0,
      merge: (currentCache, newResponse, {arg}) =>
        (arg.offset ?? 0) !== 0
          ? {
              ...newResponse,
              data: [...(currentCache.data || []), ...newResponse.data]
            }
          : newResponse,
      query: queryParams => buildUrl('/api/v1/user/profile/past-applications', queryParams),
      serializeQueryArgs: ({endpointName}) => endpointName,
      transformResponse: transformUserPastApplications
    }),
    getUserProfile: builder.query<GetUserProfileResponse, undefined>({
      keepUnusedDataFor: 0,
      query: () => '/api/v1/user/profile',
      transformResponse: transformApplicationFormStructureResponse
    }),
    getUserProfileFileUploadConfig: builder.query<
      GetFileUploadConfigResponse,
      Omit<GetFileUploadConfigParams, 'jobId'>
    >({
      query: ({id, params}) => ({
        method: 'GET',
        params,
        url: `/api/v1/user/profile/upload/${id ?? 'undefined'}`
      })
    }),
    getUserProfilePreferences: builder.query<GetUserProfilePreferencesResponse, undefined>({
      query: () => '/api/v2/user/profile/preferences'
    }),
    getUserProfileUploadedCVDetails: builder.query<
      GetUploadedCVDetailsResponse,
      Omit<GetUploadedCVDetailsParams, 'jobId'>
    >({
      query: ({headers, params}) => ({
        headers,
        method: 'GET',
        params,
        url: `api/v1/user/profile/autofill`
      })
    }),
    jobAttribution: builder.mutation<undefined, JobAttributionParams>({
      query: jobAttributionQuery
    }),
    patchUserProfile: builder.mutation<void, PatchUserProfileParams>({
      invalidatesTags: ['UserData'],
      query: profile => ({
        body: profile,
        credentials: 'same-origin',
        method: 'PATCH',
        url: '/api/v2/user/profile'
      })
    }),
    saveUserProfile: builder.mutation<undefined, Omit<ApplyParams, 'jobId' | 'jobPosition' | 'lng'>>({
      invalidatesTags: ['ApplicationFormStructure', 'UserData'],
      query: ({formValues, headers, unverifiedStructuredProfile}) => ({
        body: {profile: formValues, unverifiedStructuredProfile},
        credentials: 'same-origin',
        headers,
        method: 'POST',
        url: '/api/v1/user/profile'
      })
    })
  }),
  reducerPath: 'jobBoard',
  tagTypes: ['UserData', 'ApplicationFormStructure']
});

export const {
  useApplyMutation,
  useCompanyAttributionMutation,
  useFindAvatarUsingEmailMutation,
  useGetApplicationFormStructureByIdQuery,
  useGetJobByIdQuery,
  useGetJobsQuery,
  useGetPaginatedCompanyByIdQuery,
  useGetPaginatedJobsQuery,
  useGetRecentSearchesQuery,
  useGetSimilarJobsByIdQuery,
  useGetSponsorsQuery,
  useGetTitleSearchAutocompleteQuery,
  useGetUserDataQuery,
  useGetUserPastApplicationsQuery,
  useGetUserProfilePreferencesQuery,
  useGetUserProfileQuery,
  useJobAttributionMutation,
  useLazyGetFileUploadConfigQuery,
  useLazyGetPaginatedCompanyByIdQuery,
  useLazyGetPaginatedJobsQuery,
  useLazyGetUploadedCVDetailsQuery,
  useLazyGetUserDataQuery,
  useLazyGetUserPastApplicationsQuery,
  useLazyGetUserProfileFileUploadConfigQuery,
  useLazyGetUserProfilePreferencesQuery,
  useLazyGetUserProfileQuery,
  useLazyGetUserProfileUploadedCVDetailsQuery,
  usePatchUserProfileMutation,
  useSaveUserProfileMutation
} = jobBoardApi;
