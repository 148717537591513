import React from 'react';
import {useTranslation} from 'react-i18next';
import search16 from 'assets/svg/search16.svg?sprite';
import clsx from 'clsx';
import TextBody from 'shared/ui/atoms/text/body';
import EllipsisWithTooltip from 'shared/ui/molecules/ellipsisWithTooltip';
import Svg from 'job-board/shared/components/svg';
import useSearchJobsParams from 'job-board/shared/hooks/useSearchJobsParams';
import {ForYouPill} from '../forYou';
import styles from './searchTrigger.scss';

type SearchTriggerProps = {
  className?: string;
  companyName?: string;
  onSearchButtonClick: () => void;
  placeholder?: string;
};

const TOOLTIP_PROPS = Object.freeze({});

const Query = ({isForYouOptionSelected, query}: {isForYouOptionSelected: boolean; query: string}): JSX.Element => {
  if (isForYouOptionSelected) {
    return <ForYouPill className={styles['for-you']} />;
  }

  return <TextBody>{query}</TextBody>;
};

export const SearchTrigger = ({
  className,
  companyName,
  onSearchButtonClick,
  placeholder
}: SearchTriggerProps): JSX.Element => {
  const {t} = useTranslation();
  const {filterByProfile, location, query} = useSearchJobsParams();

  const hasSearchTerms = !!query || !!location || !!filterByProfile;
  const isForYouOptionSelected = !!filterByProfile;

  return (
    <div className={clsx(styles.container, className)}>
      <button className={styles.button} data-ui="search-for-jobs-button" onClick={onSearchButtonClick}>
        <Svg className={styles.icon} id={search16.id} />
        <EllipsisWithTooltip tooltipProps={TOOLTIP_PROPS}>
          <span className={styles.text}>
            {hasSearchTerms ? (
              <>
                {(!!query || isForYouOptionSelected) && (
                  <Query isForYouOptionSelected={isForYouOptionSelected} query={query} />
                )}
                {!!companyName && <TextBody>{companyName}</TextBody>}
                {!!location && <TextBody>{location}</TextBody>}
              </>
            ) : (
              <TextBody secondary>{placeholder ?? t('shared.search-input.job-title.placeholder')}</TextBody>
            )}
          </span>
        </EllipsisWithTooltip>
      </button>
    </div>
  );
};
