import React, {useState, useEffect, useCallback, useRef} from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import Tooltip from 'shared/ui/organisms/tooltip';
import propsFilter from 'shared/ui/helpers/propsFilter';

import styles from './styles.scss';

const EllipsisWithTooltip = React.memo(({children, tooltipProps, ...props}) => {
  const [hasEllipsis, setHasEllipsis] = useState(false);

  const composePropsFilter = propsFilter(props).dataAttributes().styles();
  const containerProps = composePropsFilter.getFiltered();

  const containerRef = useRef(null);

  const checkForEllipsis = useCallback(() => {
    const containerEl = containerRef.current;

    if (!containerEl) {
      return;
    }

    const ellipsisElement = containerEl.firstElementChild;
    if (!ellipsisElement) {
      return;
    }

    const isEllipsisActive = ellipsisElement.offsetWidth < ellipsisElement.scrollWidth;
    setHasEllipsis(isEllipsisActive);
  }, []);

  useEffect(() => {
    const containerEl = containerRef.current;

    if (!containerEl || typeof ResizeObserver !== 'function') {
      return;
    }

    const resizeObserver = new ResizeObserver(debounce(checkForEllipsis, 50));

    resizeObserver.observe(containerEl);

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkForEllipsis]);

  return (
    <Tooltip {...tooltipProps} show={hasEllipsis}>
      <div
        ref={el => {
          containerRef.current = el;

          checkForEllipsis();
        }}
        {...containerProps}
        className={clsx(styles.container, containerProps.className)}
      >
        {React.cloneElement(children, {'data-ellipsis-element': true})}
      </div>
    </Tooltip>
  );
});

export default EllipsisWithTooltip;
