import i18n from 'job-board/i18n';

type LocalizedValue = string | Record<string, string>;

export const makePageDataSelector =
  <BaseObject extends Record<string, LocalizedValue>, K extends keyof BaseObject>(
    object: BaseObject,
    language: string = i18n.language
  ) =>
  <Fallback>({
    fallback,
    key
  }: {
    fallback?: Fallback;
    key?: K;
  }): Fallback extends string ? string : string | undefined => {
    type ReturnValue = Fallback extends string ? string : string | undefined;

    if (!key || object[key] === undefined) {
      return fallback as ReturnValue;
    }

    const value = object[key];

    const resolvedValue = typeof value === 'string' ? value : value[language] || value.en;

    if (!resolvedValue) {
      return fallback as ReturnValue;
    }

    return resolvedValue;
  };
