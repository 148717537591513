import {BaseQueryMeta} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {BaseQueryFn} from '@reduxjs/toolkit/query/react';
import {SEARCH_ID_HEADER} from 'job-board/api/constants';
import {getRequestHeaderValueFromMeta} from 'job-board/api/helpers';
import {SimplifiedJobResponse, SimplifiedTransformedJob} from 'job-board/api/types/common';
import {GetApplicationFormStructureResponse} from 'job-board/api/types/getApplicationFormStructure';
import {GetJobResponse, TransformedJob, Workplace} from 'job-board/api/types/getJob';
import {GetJobsResponse, TransformedJobs} from 'job-board/api/types/getJobs';
import {GetPaginatedCompanyResponse, TransformedPaginatedCompany} from 'job-board/api/types/getPaginatedCompany';
import {GetRecentSearchesResponse, TransformedRecentSearches} from 'job-board/api/types/getRecentSearches';
import {GetSponsorsResponse, TransformedGetSponsorsResponse} from 'job-board/api/types/getSponsors';
import {GetUserDataResponse} from 'job-board/api/types/getUserData';
import {
  GetUserPastApplicationsResponse,
  TransformedUserPastApplications
} from 'job-board/api/types/getUserPastApplications';

export const getRelativeUrl = (url: string, splitter: string | RegExp): string => {
  if (!url) {
    return '';
  }
  const splitUrl = url.split(splitter);

  return splitUrl[splitUrl.length - 1];
};

const transformSimplifiedJobResponse = (simplifiedJobResponse: SimplifiedJobResponse): SimplifiedTransformedJob => ({
  applicationDate: simplifiedJobResponse.applicationDate ?? '',
  company: {
    ...simplifiedJobResponse.company,
    relativeUrl: getRelativeUrl(simplifiedJobResponse.company.url, /(?=\/company)/g),
    url: simplifiedJobResponse.company.url
  },
  dateCreated: simplifiedJobResponse.created,
  dateUpdated: simplifiedJobResponse.updated,
  department: simplifiedJobResponse.department ?? '',
  description: simplifiedJobResponse.description,
  employmentType: simplifiedJobResponse.employmentType?.replace('-', ' ') ?? '',
  externalDomain: simplifiedJobResponse.externalDomain,
  id: simplifiedJobResponse.id,
  location: simplifiedJobResponse.locations.find(location => location !== 'TELECOMMUTE') ?? '',
  relativeUrl: getRelativeUrl(simplifiedJobResponse.url, /(?=\/view)/g),
  state: simplifiedJobResponse.state,
  title: simplifiedJobResponse.title,
  url: simplifiedJobResponse.url,
  workplace: simplifiedJobResponse.workplace ?? Workplace.ON_SITE
});

export const transformJobResponse = (job: GetJobResponse): TransformedJob => {
  return {
    ...transformSimplifiedJobResponse(job),
    applyUrl: job.applyUrl,
    benefitsSection: job.benefitsSection,
    isFeatured: !!job.isFeatured,
    language: job.language ?? 'en',
    requirementsSection: job.requirementsSection,
    trackingPixelUrl: job.trackingPixelUrl
  };
};

export const transformJobsResponse = (
  response: GetJobsResponse,
  meta?: {request?: Request; response?: Response}
): TransformedJobs => {
  const searchId = meta ? {searchId: getRequestHeaderValueFromMeta(meta, SEARCH_ID_HEADER)} : {};

  return {
    ...response,
    ...searchId,
    jobs: response.jobs.map(transformJobResponse)
  };
};

export const transformPaginatedCompanyResponse = (
  response: GetPaginatedCompanyResponse
): TransformedPaginatedCompany => ({
  ...response,
  jobs: response.jobs.map(transformJobResponse)
});

export const transformApplicationFormStructureResponse = (
  formSections: GetApplicationFormStructureResponse,
  meta: BaseQueryMeta<BaseQueryFn<unknown, unknown, unknown, unknown, {response: Response}>>
): GetApplicationFormStructureResponse =>
  formSections.map(section => ({
    ...section,
    ...('fields' in section && {
      fields: section.fields.map(field => {
        if (field.type === 'phone') {
          return {...field, countryCode: meta?.response.headers.get('x-country')};
        }

        return field;
      })
    })
  }));

export const transformRecentSearchesResponse = (response: GetRecentSearchesResponse): TransformedRecentSearches =>
  response.map(recentSearch => ({
    ...recentSearch,
    relativeUrl: getRelativeUrl(recentSearch.url, /(?=\/search)/g)
  }));

export const transformSponsorsResponse = (response: GetSponsorsResponse): TransformedGetSponsorsResponse =>
  Object.entries(response).reduce(
    (transformedSponsors, [tier, sponsors]) => ({
      ...transformedSponsors,
      [tier]: sponsors.map(sponsor => ({
        ...sponsor,
        relativeUrl: getRelativeUrl(sponsor.url, /(?=\/company)/g)
      }))
    }),
    {} as TransformedGetSponsorsResponse
  );

export const transformUserPastApplications = (
  pastApplications: GetUserPastApplicationsResponse
): TransformedUserPastApplications => ({
  ...pastApplications,
  data: pastApplications.data.map(transformSimplifiedJobResponse)
});

export const transformUserData = (userData: GetUserDataResponse): GetUserDataResponse => ({
  ...userData,
  hasExploredProfile: userData.hasExploredProfile ?? userData.isCompleteProfile
});
