import React, {Suspense} from 'react';
import {Navigate, Outlet, Route} from 'react-router-dom';
import type {ApiError} from 'job-board/api/types/api';
import {isServerError} from 'job-board/api/utils/errors';
import Attributions from 'job-board/shared/components/attributions';
import LazyLoadedWelcomeModal from 'job-board/shared/components/authenticationFlow/components/welcome/lazyLoadedWelcomeModal';
import CookieConsent from 'job-board/shared/components/cookieConsent';
import ServerErrorContainer from 'job-board/shared/components/errors/components/serverErrorContainer';
import FlashAlert from 'job-board/shared/components/flashAlert';
import Layout from 'job-board/shared/components/layout';
import Loader from 'job-board/shared/components/loader';
import ProtectedRoute from 'job-board/shared/components/protectedRoute';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import lazyLoadWithRetry from 'job-board/shared/utils/lazyImport';
import {routesPaths} from './constants';
import useAppMount from './hooks/useAppMount';
import AppProviders from './providers';

const Home = lazyLoadWithRetry(() => import(/* webpackChunkName: 'page.home' */ './home'), 'page.home');
const Search = lazyLoadWithRetry(() => import(/* webpackChunkName: 'page.search' */ './search'), 'page.search');
const Job = lazyLoadWithRetry(() => import(/* webpackChunkName: 'page.job' */ './job'), 'page.job');
const Company = lazyLoadWithRetry(() => import(/* webpackChunkName: 'page.company' */ './company'), 'page.company');
const NotFound = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'page.not-found' */ './notFound'),
  'page.not-found'
);
const NotAuthorized = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'page.not-authorized' */ './notAuthorized'),
  'page.not-authorized'
);
const ProfileBaseRoute = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'page.profile' */ './profile'),
  'page.profile'
);
const MyProfile = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'page.profile.my-profile' */ './profile/routes/myProfile'),
  'page.profile.my-profile'
);
const AppliedJobs = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'page.profile.applied-jobs' */ './profile/routes/appliedJobs'),
  'page.profile.applied-jobs'
);
const Settings = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'page.profile.settings' */ './profile/routes/settings'),
  'page.profile.settings'
);
const Login = lazyLoadWithRetry(() => import(/* webpackChunkName: 'page.login' */ './login'), 'page.login');
const SuccessfulUnsubscribe = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'page.successfulUnsubscribe' */ './successfulUnsubscribe'),
  'page.successfulUnsubscribe'
);

export const AppRoutesWrapper = (): JSX.Element => {
  const {userDataError} = useAppMount();

  return (
    <Suspense fallback={null}>
      <Layout>
        <ServerErrorContainer hasServerError={isServerError(userDataError as ApiError)}>
          <FlashAlert />
          <Outlet />
          <LazyLoadedWelcomeModal />
        </ServerErrorContainer>
        <CookieConsent />
      </Layout>
      <Attributions />
    </Suspense>
  );
};

const AppRoutes = (
  <Route
    element={
      <AppProviders>
        <AppRoutesWrapper />
      </AppProviders>
    }
  >
    <Route element={<Home />} path={routesPaths.HOME} />
    <Route path={routesPaths.SEARCH.index}>
      <Route element={<Search />} index />
      {routesPaths.SEARCH.subPaths.map(subPath => (
        <Route element={<Search />} key={subPath} path={subPath} />
      ))}
      <Route element={<Navigate replace to={routesPaths.SEARCH.index} />} path="*" />
    </Route>
    <Route element={<Job />} path={`${routesPaths.JOB}/:jobId`}>
      <Route element={<Job />} path=":jobDescription" />
    </Route>
    <Route element={<Company />} path={`${routesPaths.COMPANY}/:companyId`}>
      <Route element={<Company />} path=":companyDescription" />
    </Route>
    {FEATURE_FLAGS.IS_PROFILE_ENABLED && (
      <Route
        element={
          <ProtectedRoute>
            <ProfileBaseRoute />
          </ProtectedRoute>
        }
        path={routesPaths.PROFILE.index}
      >
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <MyProfile />
            </Suspense>
          }
          path={routesPaths.PROFILE.subRoutes.MY_PROFILE}
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <AppliedJobs />
            </Suspense>
          }
          path={routesPaths.PROFILE.subRoutes.APPLIED_JOBS}
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <Settings />
            </Suspense>
          }
          path={routesPaths.PROFILE.subRoutes.SETTINGS}
        />
        <Route element={<Navigate replace to={routesPaths.PROFILE.index} />} path="*" />
      </Route>
    )}
    {FEATURE_FLAGS.IS_PROFILE_ENABLED && (
      <Route element={<Navigate replace to={routesPaths.HOME} />} path={routesPaths.BACKEND_ACCOUNT_DELETE} />
    )}
    {FEATURE_FLAGS.IS_PROFILE_ENABLED && (
      <Route
        element={
          <ProtectedRoute shouldRedirectWhenLoggedIn>
            <Login />
          </ProtectedRoute>
        }
        path={routesPaths.LOGIN}
      />
    )}
    <Route element={<SuccessfulUnsubscribe />} path={routesPaths.SUCCESSFUL_UNSUBSCRIBE} />
    <Route
      element={
        <ProtectedRoute shouldRedirectWhenLoggedIn={false}>
          <NotAuthorized />
        </ProtectedRoute>
      }
      path={routesPaths.COMPANY_NOT_AUTHORIZED}
    />
    <Route element={<NotFound />} path="*" />
  </Route>
);

export default AppRoutes;
