import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import type {SerializedError} from '@reduxjs/toolkit';
import type {FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import ga4 from 'shared/helpers/analytics/ga4';
import {useLazyGetUserDataQuery} from 'job-board/api/jobBoardApi';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {HeapProperty} from 'job-board/shared/analytics/events/heap';
import {HotjarProperty} from 'job-board/shared/analytics/events/hotjar';
import {watchForConsent} from 'job-board/shared/analytics/helpers/trackConsent';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import useHandleBroadcastAuth from 'job-board/shared/hooks/useHandleBroadcastAuth';
import {isPreviewMode} from 'job-board/shared/utils/mode';
import {authWithShield} from './utils/authWithShield';

const useAppMount = (): {userDataError?: FetchBaseQueryError | SerializedError} => {
  const [getUserData, {error}] = useLazyGetUserDataQuery();

  const {
    addHeapEventProperties,
    addHeapEventProperty,
    heapIdentify,
    hotjarIdentify,
    removeHeapEventProperties,
    resetHeapIdentity,
    resetHotjarIdentity
  } = useAnalytics();

  const {i18n} = useTranslation();

  useEffect(() => {
    addHeapEventProperty(HeapProperty.LANGUAGE_ENABLED, i18n.language.toUpperCase());

    hotjarIdentify(null, {
      [HotjarProperty.LANGUAGE_ENABLED]: i18n.language.toUpperCase()
    });

    Object.entries(window.jobBoard.featureFlags || {}).forEach(([key, value]) => {
      const featureFlagValue = value?.toString ? value.toString() : value;

      addHeapEventProperties({
        // Heap doesn't play well with boolean values
        [key]: featureFlagValue
      });
    });

    if (FEATURE_FLAGS.IS_PROFILE_ENABLED) {
      const getUser = async ({shouldAuthWithShield}: {shouldAuthWithShield: boolean}): Promise<void> => {
        const {data: user, error: userDataError} = await getUserData(undefined);

        if (!user && shouldAuthWithShield) {
          await authWithShield();

          return getUser({shouldAuthWithShield: false});
        }

        if (user && !userDataError) {
          heapIdentify(user.id);

          const userLanguage = user.preferences.language;

          hotjarIdentify(user.id, {
            [HotjarProperty.LOGGED_IN]: 'true',
            [HotjarProperty.CANDIDATE_PROFILE_COMPLETED]: user.isCompleteProfile.toString(),
            [HotjarProperty.LANGUAGE_ENABLED]: (userLanguage ?? i18n.language).toUpperCase()
          });

          addHeapEventProperties({
            [HeapProperty.LOGGED_IN]: 'true',
            [HeapProperty.CANDIDATE_PROFILE_COMPLETED]: user.isCompleteProfile.toString(),
            [HeapProperty.LANGUAGE_ENABLED]: (userLanguage ?? i18n.language).toUpperCase()
          });
        } else {
          resetHeapIdentity();

          resetHotjarIdentity();

          hotjarIdentify(null, {
            [HotjarProperty.LOGGED_IN]: 'false',
            [HotjarProperty.CANDIDATE_PROFILE_COMPLETED]: 'false',
            [HotjarProperty.LANGUAGE_ENABLED]: i18n.language.toUpperCase()
          });

          removeHeapEventProperties(HeapProperty.LOGGED_IN, HeapProperty.CANDIDATE_PROFILE_COMPLETED);
        }
      };

      void getUser({shouldAuthWithShield: !isPreviewMode()});
    }

    // Inject ga4 script used by VJB partners
    void watchForConsent().then(({areAnalyticsCookiesEnabled}) => {
      const googleAnalyticsKey = window.jobBoard.config.googleAnalyticsKey;

      if (areAnalyticsCookiesEnabled && googleAnalyticsKey) {
        ga4(googleAnalyticsKey);
      }
    });
  }, []);

  useHandleBroadcastAuth();

  return {
    userDataError: error
  };
};

export default useAppMount;
