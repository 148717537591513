import React, {memo, type ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import external16 from 'assets/svg/external16.svg?sprite';
import clsx from 'clsx';
import Text from 'shared/ui/atoms/text/body';
import Svg from 'job-board/shared/components/svg';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {NEED_HELP_LINK} from 'job-board/shared/constants/needHelpLinks';
import {useBackendValue} from 'job-board/shared/hooks/useBackendValue';
import styles from './bottomRow.scss';

const BottomRowListItem = ({children}: {children: ReactNode}): JSX.Element => (
  <li className={styles['links-list-item']}>{children}</li>
);

const BottomRow = (): JSX.Element => {
  const {i18n, t} = useTranslation();
  const {pickBackendUrl} = useBackendValue();

  const urls = {
    accessibility: pickBackendUrl({key: 'accessibility'}),
    dsa: pickBackendUrl({key: 'dsa'}),
    helpCenter: pickBackendUrl({fallback: NEED_HELP_LINK[i18n.language as 'en' | 'el'], key: 'helpCenter'}),
    privacyPolicy: pickBackendUrl({key: 'privacyPolicy'}),
    signUp: pickBackendUrl({key: 'signUp'}),
    termsAndConditions: pickBackendUrl({key: 'termsAndConditions'})
  };

  return (
    <div className={styles.container}>
      <Text className={clsx(styles.text, styles.copyright)}>
        {t('page-data.footerText') ? (
          <>
            <span>{t('page-data.footerText')} </span>
            <span className={styles['powered-by-text']}>
              {t('footer.poweredByWorkableText', {
                currentYear: new Date().getFullYear()
              })}
            </span>
          </>
        ) : (
          t('footer.workableText', {currentYear: new Date().getFullYear()})
        )}
      </Text>
      <ul className={styles['links-list']}>
        {!!urls.signUp && (
          <BottomRowListItem>
            <a className={styles.link} href={urls.signUp} rel="noreferrer" target="_blank">
              <span>{t('footer.link.post-job')}</span>
              <Svg className={styles['external-icon']} id={external16.id} />
            </a>
          </BottomRowListItem>
        )}
        {!!urls.termsAndConditions && (
          <BottomRowListItem>
            <a className={styles.link} href={urls.termsAndConditions}>
              {t('footer.link.terms')}
            </a>
          </BottomRowListItem>
        )}
        {!!urls.dsa && (
          <BottomRowListItem>
            <a className={styles.link} href={urls.dsa}>
              {t('footer.link.dsa')}
            </a>
          </BottomRowListItem>
        )}
        {!!urls.privacyPolicy && (
          <BottomRowListItem>
            <a className={styles.link} href={urls.privacyPolicy}>
              {t('footer.link.privacy')}
            </a>
          </BottomRowListItem>
        )}
        {!!urls.accessibility && (
          <BottomRowListItem>
            <a className={styles.link} href={urls.accessibility}>
              {t('footer.link.accessibility')}
            </a>
          </BottomRowListItem>
        )}
        {FEATURE_FLAGS.IS_PROFILE_ENABLED && (
          <BottomRowListItem>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a className={styles.link} href={urls.helpCenter} target="_blank">
              <span>{t('shared.text.need-help')}</span>
              <Svg className={styles['external-icon']} id={external16.id} />
            </a>
          </BottomRowListItem>
        )}
      </ul>
    </div>
  );
};

export default memo(BottomRow);
