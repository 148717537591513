export const OPT_OUT_COOKIE = '__hs_opt_out';
export const COOKIE_CATEGORIES_PREFERENCES = '__hs_cookie_cat_pref';
export const DO_NOT_OPT_OUT = 'no';
export const OPT_OUT = 'yes';

export const getOptOutCookieValue = (name = OPT_OUT_COOKIE): string => {
  const matchCookieNameRegex = new RegExp('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');

  return matchCookieNameRegex.exec(document.cookie)?.pop() ?? '';
};

const isPreferenceEnabled = (preferenceByCategory: string | undefined): boolean =>
  preferenceByCategory?.split(':')?.[1] === 'true';

type CookiePreferences = {
  areAdvertisementCookiesEnabled: boolean;
  areAnalyticsCookiesEnabled: boolean;
  areFunctionalityCookiesEnabled: boolean;
};

export const getCookieCategoriesPreferences = (name = COOKIE_CATEGORIES_PREFERENCES): CookiePreferences => {
  const matchCookieNameRegex = new RegExp('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');

  const preferences = (matchCookieNameRegex.exec(document.cookie)?.pop() ?? '').split(',');

  return {
    areAdvertisementCookiesEnabled: isPreferenceEnabled(preferences[1]),
    areAnalyticsCookiesEnabled: isPreferenceEnabled(preferences[0]),
    areFunctionalityCookiesEnabled: isPreferenceEnabled(preferences[2])
  };
};

type Consent = {
  hasGivenConsent: boolean;
} & CookiePreferences;

export const watchForConsent = (): Promise<Consent> =>
  new Promise(res => {
    const interval = setInterval(() => {
      const optOutCookieValue = getOptOutCookieValue();
      const isConsentPresent = optOutCookieValue !== '';
      const hasGivenConsent = optOutCookieValue === DO_NOT_OPT_OUT;

      if (isConsentPresent && !hasGivenConsent) {
        clearInterval(interval);
        res({
          areAdvertisementCookiesEnabled: false,
          areAnalyticsCookiesEnabled: false,
          areFunctionalityCookiesEnabled: false,
          hasGivenConsent: false
        });
        return;
      }

      if (hasGivenConsent) {
        clearInterval(interval);
        res({
          hasGivenConsent: true,
          ...getCookieCategoriesPreferences()
        });
      }
    }, 1000);
  });
