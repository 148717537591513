import {useEffect, useState} from 'react';
import {register, unregister} from 'job-board/shared/utils/swRegistration';

export default (options?: RegistrationOptions): void => {
  const [error, setError] = useState<unknown>();

  const isSwDisabled = window.jobBoard.config.swDisabled;

  useEffect(() => {
    if (isSwDisabled) {
      unregister()
        .then(() => {
          window.location.reload();
        })
        .catch(e => {
          setError(e);
        });
    } else {
      register({
        ...options,
        onUpdate: registration => {
          registration?.waiting?.postMessage({type: 'SKIP_WAITING'});
        }
      });
    }
  }, [options, isSwDisabled]);

  if (error) {
    throw error;
  }
};
