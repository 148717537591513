export const getAvailableLanguages = (): LanguageConfig[] => window.jobBoard?.supportedLanguages ?? [];

export const getAvailableLanguageCodes = (): string[] => getAvailableLanguages().map(({code}) => code);

export const hasLanguageOptions = (): boolean => getAvailableLanguages().length > 1;

export const isLanguageAvailable = (language?: string): boolean =>
  getAvailableLanguageCodes().some(locale => locale === language);

export const getLocaleFromPathname = (pathname: string): string | undefined => {
  const HAS_URL_LOCALE_REGEX = new RegExp(`^/(${getAvailableLanguageCodes().join('|')})([^\\w]|$)`);

  const [localePrefix] = HAS_URL_LOCALE_REGEX.exec(pathname) ?? [];

  const language = localePrefix?.replace(/\//g, '');

  if (isLanguageAvailable(language)) {
    return language;
  }
};

export const getUrlWithoutLocale = ({
  hash,
  pathname,
  search
}: {
  hash: string;
  pathname: string;
  search: string;
}): string => {
  const localePrefix = getLocaleFromPathname(pathname);
  const affix = search + hash;

  if (localePrefix) {
    const trimmedPathname = pathname.replace(new RegExp(`^/${localePrefix}`), '') || '/';

    return `${trimmedPathname}${affix}`;
  }

  return `${pathname}${affix}`;
};

export const getUrlWithLocale = ({locale, url}: {locale: string; url: string}): string => {
  if (!isLanguageAvailable(locale)) {
    return url;
  }

  const {hash, origin, pathname, search} = new URL(url, window.location.origin);

  const hasLocale = !!getLocaleFromPathname(pathname);

  if (hasLocale) {
    return url;
  }

  return `${origin}/${locale}${pathname}${search}${hash}`;
};
