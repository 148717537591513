import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {shallowEqual, useDispatch} from 'react-redux';
import {GetUserDataResponse} from 'job-board/api/types/getUserData';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {useSelector} from 'job-board/redux';
import {HeapProperty} from 'job-board/shared/analytics/events/heap';
import {HotjarProperty} from 'job-board/shared/analytics/events/hotjar';
import {useLanguage} from 'job-board/shared/hooks/useLanguage';
import {selectIsUserAuthenticated, selectUserData} from './selectors';
import {clearState, clearUserDataDueToError, setUserData, UserDataState} from './slice';

export const useUserDataActions = () => {
  const dispatch = useDispatch();
  const {addHeapEventProperties, heapIdentify, hotjarIdentify} = useAnalytics();
  const {updateLanguage} = useLanguage();
  const {i18n} = useTranslation();

  const updateUserAfterLogin = useCallback(
    (user: GetUserDataResponse) => {
      dispatch(setUserData(user));

      const userLanguage = user.preferences.language;

      addHeapEventProperties({
        [HeapProperty.LOGGED_IN]: 'true',
        [HeapProperty.CANDIDATE_PROFILE_COMPLETED]: user.isCompleteProfile.toString(),
        [HeapProperty.LANGUAGE_ENABLED]: (userLanguage ?? i18n.language).toUpperCase()
      });

      heapIdentify(user.id);

      hotjarIdentify(user.id, {
        [HotjarProperty.LOGGED_IN]: 'true',
        [HotjarProperty.CANDIDATE_PROFILE_COMPLETED]: user.isCompleteProfile.toString(),
        [HotjarProperty.LANGUAGE_ENABLED]: (userLanguage ?? i18n.language).toUpperCase()
      });

      if (userLanguage) {
        void updateLanguage(userLanguage);
      }
    },
    [hotjarIdentify, updateLanguage]
  );

  return {
    clearState: useCallback(() => dispatch(clearState()), []),
    clearUserDataDueToError: useCallback(() => dispatch(clearUserDataDueToError()), []),
    setUserData: useCallback((payload: GetUserDataResponse) => {
      dispatch(setUserData(payload));
    }, []),
    updateUserAfterLogin
  };
};

export const useUserData = (): UserDataState => useSelector(selectUserData, shallowEqual);

export const useIsUserAuthenticated = (): boolean => useSelector(selectIsUserAuthenticated);
