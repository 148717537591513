import React, {useCallback, useState, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import propsFilter from 'shared/ui/helpers/propsFilter';
import getRandomString from 'shared/ui/helpers/getRandomString';

import Ref from 'shared/ui/behaviors/ref';
import TextSecondary from 'shared/ui/atoms/text/secondary';
import {AnchoredDropdown as Dropdown, POSITIONS} from 'shared/ui/organisms/dropdown';

import {TRANSITION_DURATION} from 'shared/ui/organisms/dialog/base/container';

export const INSTANT_OPEN_THRESHOLD = 600;

import styles from './styles.scss';

export const PLACEMENTS = Object.freeze({
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  topLeft: 'top-left',
  topRight: 'top-right',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right'
});

const TEXT_ALIGNMENTS = Object.freeze({
  left: 'left',
  right: 'right',
  center: 'center'
});

const STATUSES = Object.freeze({
  open: 'open',
  closing: 'closing'
});

const TEXT_KINDS = {
  title: 'title',
  content: 'content'
};

const COLORS = {
  [TEXT_KINDS.title]: {
    default: 'white',
    inverted: 'grey700'
  },
  [TEXT_KINDS.content]: {
    default: 'grey300',
    inverted: 'grey600'
  }
};

const Text = ({text, textKind, inverted, ...restProps}) => {
  if (!text) {
    return null;
  }

  const props = {
    neutral: true,
    color: inverted ? COLORS[textKind].inverted : COLORS[textKind].default,
    ...restProps
  };

  return <TextSecondary {...props}>{text}</TextSecondary>;
};

/**
 *
 * @param {'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'} placement
 * @returns [vertical, horizontal]
 */
const getCoordinatesFromPlacementString = placement => {
  switch (placement) {
    case PLACEMENTS.top:
      return [POSITIONS.TOP, POSITIONS.HORIZONTAL_CENTER];
    case PLACEMENTS.bottom:
      return [POSITIONS.BOTTOM, POSITIONS.HORIZONTAL_CENTER];
    case PLACEMENTS.left:
      return [POSITIONS.VERTICAL_CENTER, POSITIONS.LEFT];
    case PLACEMENTS.right:
      return [POSITIONS.VERTICAL_CENTER, POSITIONS.RIGHT];
    case PLACEMENTS.topRight:
      return [POSITIONS.TOP, POSITIONS.RIGHT];
    case PLACEMENTS.topLeft:
      return [POSITIONS.TOP, POSITIONS.LEFT];
    case PLACEMENTS.bottomLeft:
      return [POSITIONS.BOTTOM, POSITIONS.LEFT];
    case PLACEMENTS.bottomRight:
      return [POSITIONS.BOTTOM, POSITIONS.RIGHT];
    default:
      return [POSITIONS.TOP, POSITIONS.HORIZONTAL_CENTER];
  }
};

/**
 *
 * @param {number[]} timeoutIds
 * @returns void
 */
const clearTimeouts = timeoutIds => timeoutIds.forEach(id => clearTimeout(id));

const Tooltip = ({
  show = true,
  delay = 300,
  placement = PLACEMENTS.top,
  beak = false,
  title,
  content,
  inverted,
  fixed,
  id: _id,
  children,
  onClick,
  textAlign,
  textNoWrap,
  ...restProps
}) => {
  const randomIdRef = useRef(getRandomString());
  const openTimeoutRef = useRef([]);
  const transitionTimeoutRef = useRef([]);

  const id = _id || randomIdRef.current;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [targetElement, setTargetElement] = useState();

  // is actually a touch device
  const isMobileOrTablet = 'ontouchstart' in window || 'msMaxTouchPoints' in navigator;

  const {...extraProps} = propsFilter(restProps).dataAttributes().styles().getFiltered();

  const transitionProps = propsFilter(restProps)
    .like(/^onTransition/)
    .getFiltered();

  const position = useMemo(() => {
    const [defaultVerticalPosition, defaultHorizontalPosition] = getCoordinatesFromPlacementString(placement);

    return {
      defaultVerticalPosition,
      defaultHorizontalPosition
    };
  }, [placement]);

  const activateTooltip = useCallback(
    e => {
      if (e.target.getAttribute('focus-back')) {
        return;
      }

      if (document.body.getAttribute('data-has-tooltip')) {
        document.body.setAttribute('data-has-tooltip', STATUSES.open);
        setIsTooltipVisible(true);
        return;
      }

      const openTimeoutId = setTimeout(() => {
        setIsTooltipVisible(true);
      }, delay);
      openTimeoutRef.current.push(openTimeoutId);

      const transitionTimeoutId = setTimeout(() => {
        document.body.setAttribute('data-has-tooltip', STATUSES.open);
      }, delay + TRANSITION_DURATION);
      transitionTimeoutRef.current.push(transitionTimeoutId);
    },
    [delay]
  );

  const deactivateTooltip = useCallback(() => {
    clearTimeouts(openTimeoutRef.current);
    setIsTooltipVisible(false);
    clearTimeouts(transitionTimeoutRef.current);

    if (document.body.getAttribute('data-has-tooltip')) {
      document.body.setAttribute('data-has-tooltip', STATUSES.closing);
      setTimeout(() => {
        if (document.body.getAttribute('data-has-tooltip') === STATUSES.closing) {
          document.body.removeAttribute('data-has-tooltip');
        }
      }, INSTANT_OPEN_THRESHOLD);
    }
  }, []);

  const deactivateTooltipOnTouch = useCallback(
    e => {
      // stop tooltip from re-opening when clicking the targetElement immediately after closing
      if (targetElement.contains(e.target)) {
        e.stopPropagation();
      }
      deactivateTooltip();
      window.removeEventListener('touchstart', deactivateTooltipOnTouch, true);
    },
    [deactivateTooltip, targetElement]
  );

  useEffect(() => {
    return () => {
      clearTimeouts(openTimeoutRef.current);
      clearTimeouts(transitionTimeoutRef.current);
    };
  }, []);

  useLayoutEffect(() => {
    const deactivateTooltipOnScroll = e => {
      deactivateTooltip(e);
      if (isMobileOrTablet) {
        window.removeEventListener('touchstart', deactivateTooltipOnTouch, true);
      }
    };

    window.addEventListener('scroll', deactivateTooltipOnScroll, {capture: true});

    return () => window.removeEventListener('scroll', deactivateTooltipOnScroll, {capture: true});
  }, [deactivateTooltip, deactivateTooltipOnTouch, isMobileOrTablet]);

  const setTargetElementRef = useCallback(
    /**
     * Add the target element to state
     *
     * @param {HTMLElement | SVGElement | null} target
     */
    target => {
      const isValidTarget = target && (target instanceof HTMLElement || target instanceof SVGElement);
      const isSameTarget = targetElement === target;

      if (!isValidTarget || isSameTarget) {
        return;
      }

      setTargetElement(target);
    },
    [targetElement]
  );

  useLayoutEffect(() => {
    if (!targetElement) {
      return;
    }

    const checkForRemovedTarget = mutationsList => {
      for (const mutation of mutationsList) {
        for (const element of mutation.removedNodes) {
          if (element === targetElement) {
            setTargetElement(null);
          }
        }
      }
    };

    /**
     * This is needed because some icons are loaded async and would lose their event listeners otherwise.
     */
    const mutationObserver = new MutationObserver(checkForRemovedTarget);
    if (targetElement.parentNode) {
      mutationObserver.observe(targetElement.parentNode, {childList: true, subtree: true});
    }

    const closeTooltipAndDeactivateFocus = () => {
      const hasEnteredPage = document.visibilityState === 'visible';

      if (hasEnteredPage) {
        window.requestAnimationFrame(deactivateTooltip);
      }
    };

    const activateTooltipOnTouch = e => {
      activateTooltip(e);
      window.addEventListener('touchstart', deactivateTooltipOnTouch, true);
    };

    targetElement.setAttribute('aria-describedby', id);

    targetElement.addEventListener('focus', activateTooltip, false);
    targetElement.addEventListener('blur', deactivateTooltip, false);
    window.addEventListener('visibilitychange', closeTooltipAndDeactivateFocus);

    if (onClick) {
      targetElement.addEventListener('click', onClick, false);
    }

    if (isMobileOrTablet) {
      targetElement.addEventListener('touchstart', activateTooltipOnTouch, false);
    } else {
      targetElement.addEventListener('mouseenter', activateTooltip, false);
      targetElement.addEventListener('mouseleave', deactivateTooltip, false);
    }

    return () => {
      mutationObserver.disconnect();

      targetElement.removeEventListener('focus', activateTooltip, false);
      targetElement.removeEventListener('blur', deactivateTooltip, false);
      window.removeEventListener('visibilitychange', closeTooltipAndDeactivateFocus);

      if (onClick) {
        targetElement.removeEventListener('click', onClick, false);
      }

      if (isMobileOrTablet) {
        targetElement.removeEventListener('touchstart', activateTooltipOnTouch, false);
        window.removeEventListener('touchstart', deactivateTooltipOnTouch, true);
      } else {
        targetElement.removeEventListener('mouseenter', activateTooltip, false);
        targetElement.removeEventListener('mouseleave', deactivateTooltip, false);
      }
    };
  }, [id, targetElement, isMobileOrTablet, activateTooltip, deactivateTooltip]);

  const isOpen = !!targetElement && isTooltipVisible;

  if (show && (title || content)) {
    return (
      <>
        <Ref $ref={setTargetElementRef}>{children}</Ref>
        {ReactDOM.createPortal(
          <Dropdown
            id={id}
            open={isOpen}
            freeze={!isOpen}
            target={targetElement}
            disableAutoRevertFocus
            role="tooltip"
            aria-hidden={!isTooltipVisible}
            focusable
            {...extraProps}
            {...position}
            {...transitionProps}
            className={clsx(
              styles.tooltip,
              {
                [styles.inverted]: inverted,
                [styles.fixed]: typeof fixed !== 'undefined' ? fixed : inverted,
                [styles.large]: !!content,
                [styles.beak]: !!beak,
                [styles['text-nowrap']]: textNoWrap,
                [styles[`text-${textAlign}`]]: !!TEXT_ALIGNMENTS[textAlign]
              },
              extraProps.className
            )}
          >
            <span>
              {content ? (
                <span className={styles['content-wrapper']}>
                  {title ? (
                    <Text text={title} textKind={TEXT_KINDS.title} inverted={inverted} strong={inverted} />
                  ) : null}
                  <Text text={content} textKind={TEXT_KINDS.content} inverted={inverted} />
                </span>
              ) : (
                <Text text={title} textKind={TEXT_KINDS.title} inverted={inverted} />
              )}
            </span>
          </Dropdown>,
          document.body
        )}
      </>
    );
  }

  return <Ref>{children}</Ref>;
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  /** The title of the tooltip. Can be either a string or react element. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** The optional content of the tooltip. Can be either a string or react element. Tooltips with content are larger */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /* ID of the tooltip. */
  id: PropTypes.string,
  /* Controls whether the tooltip is displayed. */
  show: PropTypes.bool,
  /* Controls where the tooltip will appear in respect to the element it is anchored to. */
  placement: PropTypes.oneOf(Object.values(PLACEMENTS)),
  /* Controls the text alignment of the tooltip. */
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  /* Controls whether the background-color and font-color should be inverted. */
  inverted: PropTypes.bool,
  /* Controls whether the arrow should be shown. Defaults to false */
  beak: PropTypes.bool,
  /* Controls whether the tooltip will be fixed width or adaptive. */
  fixed: PropTypes.bool,
  /* The time, in milliseconds that the tooltip should wait before it is displayed. Default: 0ms */
  delay: PropTypes.number,
  /** The callback function to be called when target element is clicked. */
  onClick: PropTypes.func
};

export default Tooltip;
