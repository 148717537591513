import {useTranslation} from 'react-i18next';
import {makePageDataSelector} from 'job-board/shared/utils/pageDataSelector';

type Picker<Key> = <Fallback>(params: {
  fallback?: Fallback;
  key?: Key;
}) => Fallback extends string ? string : string | undefined;

type BackendValueMethods = {
  pickBackendMeta: Picker<BackendMetaKey>;
  pickBackendUrl: Picker<BackendUrlKey>;
};

export const useBackendValue = (): BackendValueMethods => {
  const {i18n: _i18n} = useTranslation();

  return {
    pickBackendMeta: makePageDataSelector(window.jobBoard.meta, _i18n.language),
    pickBackendUrl: makePageDataSelector(window.jobBoard.urls, _i18n.language)
  };
};
