export enum HeapAction {
  Error_Application_Inline_Validation = 'WJB_Error_Application_Inline_Validation',
  Error_Application_Server_Validation = 'WJB_Error_Application_Server_Validation',
  Error_Login_Signup = 'WJB_Error_Login_Signup',
  Import_Profile = ' WJB_Import_Profile',
  Search = 'WJB_Search',
  Submit_Application = 'WJB_Submit_Application',
  Success_Application = 'WJB_Success_Application',
  Success_Login_Signup = 'WJB_Success_Login_Signup',
  View_EA_Application = 'WJB_View_EA_Application',
  View_Error_Page = 'WJB_View_Error_Page',
  View_Job = 'WJB_View_Job'
}

export enum HeapProperty {
  LOGGED_IN = 'loggedIn',
  CANDIDATE_PROFILE_COMPLETED = 'candidateProfileCompleted',
  LANGUAGE_ENABLED = 'languageEnabled'
}

export enum SignupMethod {
  MAGIC_LINK = 'magic_link_sent',
  GOOGLE = 'google',
  LINKEDIN = 'linkedin',
  MICROSOFT = 'microsoft'
}

export enum ImportProfileOrigin {
  COMPUTER = 'computer',
  DROPBOX = 'dropbox',
  GOOGLE_DRIVE = 'google_drive'
}

export enum ProfileAutoFillMethod {
  PARTLY_FILLED = 'partly_filled',
  FULLY_FILLED = 'fully_filled'
}

export enum PageViewError {
  JOB_NOT_FOUND = 'job_not_found',
  COMPANY_NOT_FOUND = 'company_not_found',
  SERVICE_UNAVAILABLE = 'service_unavailable',
  ERROR_404 = 'error404',
  ERROR_403 = 'error403'
}

export enum JobType {
  FEATURED = 'featured',
  NON_FEATURED = 'non-featured'
}

export type JobCharacteristics = {
  jobOrigin: string;
  jobType: JobType;
};

export type ExtraHeapProps = {
  [HeapAction.Error_Application_Inline_Validation]: {
    errorName: string;
    fieldName: string;
  } & JobCharacteristics;
  [HeapAction.Error_Application_Server_Validation]: {
    errorName: string;
    fieldName: string;
  } & JobCharacteristics;
  [HeapAction.Error_Login_Signup]: {
    errorName: string;
  };
  [HeapAction.Import_Profile]: {
    origin: ImportProfileOrigin;
  };
  [HeapAction.Search]:
    | {
        searchLocation: string;
        searchRemote: string;
        searchResults: number;
        searchTerm: string;
      }
    | {
        searchLocation: string;
        searchResults: number;
        searchTerm: string;
      };
  [HeapAction.Submit_Application]: JobCharacteristics;
  [HeapAction.Success_Application]: JobCharacteristics;
  [HeapAction.Success_Login_Signup]: {
    method: SignupMethod;
  };
  [HeapAction.View_EA_Application]: {
    method: ProfileAutoFillMethod;
  };
  [HeapAction.View_Error_Page]: {
    errorName: PageViewError;
  };
  [HeapAction.View_Job]: JobCharacteristics;
};
