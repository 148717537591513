import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {HeapProperty} from 'job-board/shared/analytics/events/heap';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {useUserDataActions} from 'job-board/shared/redux/userData/hooks';
import type {AuthMessage} from 'job-board/shared/services/auth';
import {AuthChannel} from 'job-board/shared/services/auth';
import {HotjarProperty} from '../analytics/events/hotjar';

const useHandleBroadcastAuth = (): void => {
  const {i18n} = useTranslation();
  const {clearUserDataDueToError, updateUserAfterLogin} = useUserDataActions();
  const {hotjarIdentify, removeHeapEventProperties, resetHeapIdentity, resetHotjarIdentity} = useAnalytics();

  const handleMessage = useCallback(
    (message: MessageEvent<AuthMessage>) => {
      if (message.origin === window.location.origin) {
        if ('error' in message.data) {
          clearUserDataDueToError();

          resetHeapIdentity();

          resetHotjarIdentity();

          hotjarIdentify(null, {
            [HotjarProperty.LOGGED_IN]: 'false',
            [HotjarProperty.CANDIDATE_PROFILE_COMPLETED]: 'false',
            [HotjarProperty.LANGUAGE_ENABLED]: i18n.language.toUpperCase()
          });

          removeHeapEventProperties(HeapProperty.LOGGED_IN, HeapProperty.CANDIDATE_PROFILE_COMPLETED);
        } else if ('success' in message.data) {
          updateUserAfterLogin(message.data.success);
        }
      }
    },
    [hotjarIdentify, resetHotjarIdentity, updateUserAfterLogin]
  );

  useEffect(() => {
    if (FEATURE_FLAGS.IS_PROFILE_ENABLED) {
      const authChannel = AuthChannel.getInstance();
      authChannel.addEventListener('message', handleMessage);

      return () => {
        authChannel.removeEventListener('message', handleMessage);
      };
    }
  }, [handleMessage]);
};

export default useHandleBroadcastAuth;
