import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FocusTrap from 'shared/ui/behaviors/focusTrap';
import KeyboardHandlers from 'shared/ui/behaviors/keyboardHandler';
import Transition from 'shared/ui/behaviors/transition';
import symbols from 'shared/ui/symbols';
import styles from './styles.scss';
import {ROLE, CLOSE_ACTION} from './constants';
import propsFilter from 'shared/ui/helpers/propsFilter';
import {Backdrop} from '../backdrop';
import {DIALOG_ROLES} from '../constants';
import {FORM_PROPS} from './constants';

export const TRANSITION_DURATION = 300;

const noopFn = () => undefined;
const eventTypes = ['mouseup', 'touchend'];
const focusEnabledRoles = ['dialog', 'alertdialog', 'complementary'];

class Dialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogRef = React.createRef();
    this.backdropRef = React.createRef();
  }

  componentDidMount() {
    const {open} = this.props;

    if (open) {
      this.openDialog();
      this.addEventListeners();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.closeDialog();
      this.removeEventListeners();
    }

    if (!prevProps.open && this.props.open) {
      this.openDialog();
      this.addEventListeners();
    }
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  addEventListeners() {
    const {backdrop} = this.props;

    if (!backdrop) {
      this.removeEventListeners();
      eventTypes.forEach(type => document.addEventListener(type, this.eventListener));
    }
  }

  removeEventListeners() {
    eventTypes.forEach(type => document.removeEventListener(type, this.eventListener));
  }

  eventListener = event => {
    if (!this.dialogContainsElement(event.target)) {
      this.closingElement = event.target;

      // if device supports touch events, trigger onClickOutside before propagated click event gets triggered
      if ('ontouchstart' in document.documentElement) {
        this.props.onClickOutside(event);
        return;
      }

      window.requestAnimationFrame(() => {
        this.props.onClickOutside(event);
      });
    }
  };

  dialogContainsElement(element) {
    return this.dialogRef.current && this.dialogRef.current.contains(element);
  }

  assignBackdropRef = el => {
    if (!el) {
      return;
    }
    this.backdropRef.current = el;
  };

  returnsFocus() {
    return (
      !!this.props.focusBack &&
      (this.props.backdrop || !this.closingElement || this.dialogContainsElement(this.closingElement))
    );
  }

  /**
   * Returns focus to previous element and calls any `onClose` or
   * `onSubmit` defined by the user.
   * @param {*} event
   */
  onCloseAndFocusPrevious(event) {
    const {onDismiss, onConfirm, focusBack} = this.props;

    if (this.previousActiveNode && this.returnsFocus()) {
      // Add focus-back attribute to be accessed in focus listener of previousActiveNode if needed
      this.previousActiveNode.setAttribute('focus-back', 'true');

      this.previousActiveNode.focus({preventScroll: focusBack === 'preventScroll'});

      // Remove focus-back attribute after a frame as it should be no longer needed
      window.setTimeout(() => {
        this.previousActiveNode.removeAttribute('focus-back');
      });
    }

    this.closingElement = undefined;

    if (event) {
      event.preventDefault();

      const returnValue = event.currentTarget && event.currentTarget.value;

      switch (returnValue) {
        case CLOSE_ACTION.DISMISS:
        case CLOSE_ACTION.CLOSE:
        case CLOSE_ACTION.CANCEL:
          if (typeof onDismiss === 'function') {
            onDismiss(returnValue);
          }
          break;

        default:
          if (typeof onConfirm === 'function') {
            onConfirm(returnValue);
          }
      }
    }
  }

  openDialog() {
    this.previousActiveNode = document.activeElement;

    if (typeof this.props.onOpen === 'function') {
      this.props.onOpen();
    }
  }

  closeDialog = event => {
    if (!this.props.disableAutoRevertFocus) {
      this.onCloseAndFocusPrevious(event);
    }

    return false;
  };

  handleBackdropClick = event => {
    event.preventDefault();
    this.props.onClickOutside(event, {backdrop: true});
  };

  render() {
    const {backdrop, id, role, children, transparent, style, as: Kind = 'div', focusable} = this.props;
    const filteredProps = propsFilter(this.props).ariaAttributes().dataAttributes().styles().getFiltered();

    const transitionProps = propsFilter(this.props)
      .like(/^onTransition/)
      .getFiltered();

    const formProps = Kind === 'form' ? propsFilter(this.props).like(new RegExp(FORM_PROPS)).getFiltered() : {};

    const dialog = (
      <dialog
        {...filteredProps}
        className={clsx(
          {
            [styles.dialog]: true,
            [styles.transparent]: transparent
          },
          filteredProps.className
        )}
        id={id}
        role={role}
        ref={this.dialogRef}
        style={style}
        data-evergreen-dialog
        open
        onScroll={this.props.onScroll}
      >
        <Kind data-role={DIALOG_ROLES.Container} {...formProps}>
          {React.Children.map(children, child =>
            React.isValidElement(child) && child.type[symbols.Dialog.Actions]
              ? React.cloneElement(child, {onUnsupportedClose: this.closeDialog})
              : child
          )}
        </Kind>
      </dialog>
    );

    const isFocusTrapEnabled = focusable !== false ? focusable || focusEnabledRoles.indexOf(role) !== -1 : false;

    return (
      <React.Fragment>
        <Transition duration={TRANSITION_DURATION} mounted={this.props.open} {...transitionProps}>
          <FocusTrap trapEnabled={isFocusTrapEnabled}>
            <KeyboardHandlers handleEscapePressed={this.props.onEscapePress}>{dialog}</KeyboardHandlers>
          </FocusTrap>
        </Transition>

        {backdrop ? (
          <Backdrop
            open={this.props.open}
            transparent={transparent}
            ref={this.assignBackdropRef}
            onClick={this.handleBackdropClick}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

Dialog.displayName = 'Dialog.Container';

Dialog[symbols.Dialog.Container] = true;

Dialog.constants = {
  ROLE
};

Dialog.defaultProps = {
  role: ROLE.STATUS,
  open: true,
  backdrop: false,
  focusBack: true,
  onClickOutside: noopFn,
  onEscapePress: noopFn
};

Dialog.propTypes = {
  /** The id of the popup. It is required. In case there is not one generate one using `shared/ui/helpers/getRandomString` */
  id: PropTypes.string,
  /** Show backdrop or not */
  backdrop: PropTypes.bool,
  /** On close sets focus on previously focused element. Default to `true` */
  focusBack: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Force dialog to take focus. Needed when none of focusEnabledRoles is applied. */
  focusable: PropTypes.bool,
  /** When a button in Dialog.Actions is pressed which is marked as dismiss will trigger the event*/
  onDismiss: PropTypes.func,
  /** Any button in dialog which not marked as `dismiss` will trigger this event passing its `value` property */
  onConfirm: PropTypes.func,
  /** Indicates whether the modal is open or not */
  open: PropTypes.bool,
  /** The role of the dialog. Passing a role will define the overall dialog behavior*/
  role: PropTypes.oneOf(Object.values(ROLE)),
  /** Backdrop is transparent. */
  transparent: PropTypes.bool,
  /** ClassName for dialog element. */
  className: PropTypes.string,
  /** This event is triggered, when the user clicks outside of dialog. */
  onClickOutside: PropTypes.func,
  /** This event is triggered, when the ESC button is pressed. */
  onEscapePress: PropTypes.func,
  /** This event is triggered after the dialog opens. */
  onOpen: PropTypes.func
};

export default Dialog;
