import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {createCookieString} from 'shared/helpers/cookie';
import {useGetUserProfilePreferencesQuery} from 'job-board/api/jobBoardApi';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {HotjarProperty} from 'job-board/shared/analytics/events/hotjar';
import {useUserData} from 'job-board/shared/redux/userData/hooks';
import {HeapProperty} from '../analytics/events/heap';
import {getAvailableLanguages} from '../utils/locale';

type UpdateLanguage = (language: string) => Promise<void>;

export const USER_LANGUAGE_KEY = 'user_language';

export const useLanguage = (): {
  selectedLanguage: LanguageConfig | undefined;
  updateLanguage: UpdateLanguage;
} => {
  const {i18n} = useTranslation();
  const {data: userData} = useUserData();
  const {addHeapEventProperty, hotjarIdentify} = useAnalytics();
  const {data: userPreferences, refetch} = useGetUserProfilePreferencesQuery(undefined, {skip: !userData});

  const currentLanguage = i18n.language;
  const userLanguagePreference = userPreferences?.language;

  const selectedLanguage = useMemo<LanguageConfig | undefined>(() => {
    const language = userLanguagePreference || currentLanguage;

    return getAvailableLanguages().find(({code}) => code === language);
  }, [currentLanguage, userLanguagePreference]);

  const updateLanguage = useCallback<UpdateLanguage>(
    async (language: string) => {
      await i18n.changeLanguage(language);

      localStorage.setItem(USER_LANGUAGE_KEY, language);

      document.cookie = createCookieString(USER_LANGUAGE_KEY, language);

      document.documentElement.lang = language;

      addHeapEventProperty(HeapProperty.LANGUAGE_ENABLED, language.toUpperCase());

      // Refetch only if we already have a user profile
      if (userData) {
        hotjarIdentify(userData.id, {
          [HotjarProperty.LOGGED_IN]: 'true',
          [HotjarProperty.CANDIDATE_PROFILE_COMPLETED]: userData.isCompleteProfile.toString(),
          [HotjarProperty.LANGUAGE_ENABLED]: language.toUpperCase()
        });

        await refetch();
      } else {
        hotjarIdentify(null, {
          [HotjarProperty.LANGUAGE_ENABLED]: language.toUpperCase()
        });
      }
    },
    [addHeapEventProperty, hotjarIdentify, i18n, refetch, userData]
  );

  return {
    selectedLanguage,
    updateLanguage
  };
};
