import React, {Suspense} from 'react';
import {useUserData} from 'job-board/shared/redux/userData/hooks';
import lazyLoadWithRetry from 'job-board/shared/utils/lazyImport';
import {isPreviewMode} from 'job-board/shared/utils/mode';

const WelcomeModal = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.welcomeModal' */ './welcomeModal'),
  'component.welcomeModal'
);

const LazyLoadedWelcomeModal = (): JSX.Element | null => {
  const {data: userData, isUninitialized} = useUserData();

  if (isUninitialized || !userData || userData?.preferences.hasBeenInformed || isPreviewMode()) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <WelcomeModal />
    </Suspense>
  );
};

export default LazyLoadedWelcomeModal;
