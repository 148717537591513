import {mediaQueriesBuilder} from 'shared/contexts/device/responsive';

export const MOBILE_PORTRAIT_MAX_SIZE = 420;
export const MOBILE_LANDSCAPE_MIN_SIZE = 421;
export const MOBILE_LANDSCAPE_MAX_SIZE = 767;
export const TABLET_MIN_SIZE = 768;
export const TABLET_MAX_SIZE = 1024;
export const DESKTOP_MIN_SIZE = 1025;

export enum Device {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile'
}

export const MEDIA_QUERIES = mediaQueriesBuilder()
  .setLargerDevice(Device.Mobile)
  .addQueryWithSpecificity(Device.Tablet, [`(min-width: ${TABLET_MIN_SIZE}px) and (max-width: ${TABLET_MAX_SIZE}px)`])
  .addQueryWithSpecificity(Device.Desktop, [`(min-width: ${DESKTOP_MIN_SIZE}px)`]);

export default mediaQueriesBuilder()
  .setLargerDevice(Device.Mobile)
  .addQueryWithSpecificity(Device.Desktop, [`(min-width: ${TABLET_MIN_SIZE}px)`]);
